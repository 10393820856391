<template>
  <el-container style="height: 100%">
    <el-header style="display: flex;align-items: center">
      <el-row type="flex" justify="space-between" :gutter="20" style="width: 100%;">
        <el-col :span="2">
          <el-select v-model="status"
                     @change="getBillListByPage"
                     clearable
                     placeholder="开票状态">
            <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 手机号 -->
        <el-col :span="15">
          <span style="font-size: 14px; margin-right: 5px;color: black">手机号</span>
          <el-input v-model="phone" @clear="getBillListByPage" clearable
                    style="width: 160px;margin-right: 20px"></el-input>
          <!-- 公司名称 -->
          <span style="font-size: 14px; margin-right: 5px;color: black">公司名称</span>
          <el-input v-model="searchValue" @clear="getBillListByPage" clearable
                    style="width: 180px; margin-right: 20px"></el-input>
          <!-- 门店名称 -->
          <span style="font-size: 14px; margin-right: 5px;color: black">门店名称</span>
          <el-input v-model="storeName" @clear="getBillListByPage" clearable
                    style="width: 180px; margin-right: 20px"></el-input>
          <!-- 发票金额 -->
          <span style="font-size: 14px; margin-right: 5px;color: black">金额区间</span>
          <el-input v-model="startMoney" clearable style="width: 80px;color: black"></el-input>
          <span style="color: black">—</span>
          <el-input v-model="endMoney" clearable style="width: 80px;margin-right: 20px"></el-input>
          <!-- 按钮 -->
          <el-button type="success" @click="getBillListByPage">搜索</el-button>
          <el-button type="primary" @click="resetInputContent">重置</el-button>
        </el-col>
        <!-- 数字区域-->
        <el-col :span="6" style="text-align: right;">
          <el-badge :value="waitValue" class="item" type="warning">
            <el-button size="small" type="warning" plain>待开票</el-button>
          </el-badge>
          <el-badge :value="noValue" class="item" type="danger">
            <el-button size="small" type="danger" plain>未开票</el-button>
          </el-badge>
          <el-badge :value="finishValue" class="item" type="success">
            <el-button size="small" type="success" plain>已开票</el-button>
          </el-badge>
        </el-col>
        <!--  退出按钮 -->
        <el-col :span="1" style="padding: 0">
          <el-button type="danger" @click="logout">退出</el-button>
        </el-col>
      </el-row>
    </el-header>
    <el-main style="padding: 0 20px">
      <!-- 发票列表区 -->
      <el-table :data="billPageList"
                border
                stripe
                highlight-current-row
                height="100%"
                :header-cell-style="headerCellStyleObj"
                style="margin-top: 0">
        <el-table-column label="序号" fixed type="index" width="50px" align="center"></el-table-column>
        <el-table-column label="开票状态" prop="status" width="80px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.status === '1'">未开票</el-tag>
            <el-tag type="success" v-else-if="scope.row.status === '2'">已开票</el-tag>
            <el-tag type="warning" v-else>待开票</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="公司名称（发票抬头）" prop="companyName" width="180px" show-overflow-tooltip></el-table-column>
        <el-table-column label="统一社会信用代码（纳税人识别码）" prop="billCode" width="260px" show-overflow-tooltip></el-table-column>
        <el-table-column label="发票金额（元）" prop="money" width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column label="购买门店名称" prop="storeName" show-overflow-tooltip></el-table-column>
        <el-table-column label="购物小票图片" prop="filePath" width="110px" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <el-button @click="loadImage(scope.row.imageNameList)"
                       type="warning"
                       size="small"
                       icon="el-icon-picture-outline"
                       circle
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="phone" width="110px" show-overflow-tooltip></el-table-column>
        <el-table-column label="邮箱地址" prop="email" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip></el-table-column>
        <el-table-column label="原因" prop="reason" show-overflow-tooltip></el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" fixed="right" width="200px" align="center" prop="status">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.status" @change="openDialogVisible(scope.row)">
              <el-radio label="1">未开票</el-radio>
              <el-radio label="2">已开票</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
          :visible.sync="openImageDialogVisible"
          width="800px"
          style="text-align: center;"
      >
        <el-image
            style="width: 700px; height: 600px; border-radius: 5px;"
            :src="imageUrl[0]"
            :preview-src-list="imageUrl"
            fit="contain"
        >
          <div slot="placeholder" class="image-slot">
            图片加载中<span class="dot">
            <i class="el-icon-loading"></i>
          </span>
          </div>
        </el-image>
      </el-dialog>

      <el-dialog title="原因说明" :visible.sync="dialogVisible" width="35%" @close="updateDialogClosed"
                 :close-on-click-modal="false"
                 ref="updateFormRef"
                 close-on-press-escape>
        <el-input type="textarea" :rows="8" placeholder="请输入不能开发票的原因" v-model="updateForm.reason"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateBill(updateForm)" @close="updateDialogClosed">确 定</el-button>
        </span>
      </el-dialog>

    </el-main>

    <el-footer>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageNum"
          :page-sizes="[20, 50, 100]"
          :page-size="20"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background>
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      storeName:'',
      imageLoading: false,
      openImageDialogVisible: false,
      imageUrl: [],
      waitValue: 0,
      noValue: 0,
      finishValue: 0,
      statusOptions: [
        {value: '0', label: '待开票'},
        {value: '1', label: '未开票'},
        {value: '2', label: '已开票'}
      ],
      reason: '',
      typeValue: '',
      dialogVisible: false,
      headerCellStyleObj: {
        'background-color': '#409EFF',
        'color': '#fff',
        'font-weight': 400
      },
      searchValue: '', // 模糊查询参数
      phone: '',
      startMoney: '',
      endMoney: '',
      status: '0',
      page: {          // 分页参数
        pageNum: 1,
        pageSize: 20
      },
      total: 0,         // 总数量
      billPageList: [],  // 分页列表数据
      updateForm: {
        id: '',
        status: '',
        reason: '',
      },
    }
  },
  created() {
    this.getBillListByPage()
    this.getNum()
  },
  methods: {
    loadImage(val) {
      this.imageLoading = true
      this.openImageDialogVisible = true
      this.imageUrl = val
    },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    openDialogVisible(val) {
      if (val.status === '1') {
        this.dialogVisible = true
        val.status = '1'
        this.updateForm = val
      } else if (val.status === '2') {
        val.status = '2'
        this.updateBill(val)
      }
    },
    updateDialogClosed() {
      this.$refs.updateFormRef.resetFields()
    },
    // 每页多少条数据
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getBillListByPage()
    },
    // 当前页
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.getBillListByPage()
    },
    // 重置
    resetInputContent() {
      this.storeName = ''
      this.status = ''
      this.phone = ''
      this.searchValue = ''
      this.startMoney = ''
      this.endMoney = ''
      this.page.pageNum = 1
      this.page.pageSize = 20
      this.getBillListByPage()
    },
    // 获取发票分页列表数据
    async getBillListByPage() {
      const {data: res} = await this.$http.post('/bill/getBillListByPage',
          {
            startMoney: this.startMoney,
            endMoney: this.endMoney,
            phone: this.phone,
            searchValue: this.searchValue,
            status: this.status,
            storeName:this.storeName,
            page: {
              pageNum: this.page.pageNum,
              pageSize: this.page.pageSize
            }
          })
      if (res.code === 200) {
        this.billPageList = res.data.rows
        this.total = res.data.total
      } else {
        return this.$message.error(res.msg)
      }
    },
    // 获取发票统计数据
    async getNum() {
      const {data: res} = await this.$http.post('/bill/getNum', {})
      if (res.code === 200) {
        this.waitValue = res.data.waitValue
        this.noValue = res.data.noValue
        this.finishValue = res.data.finishValue
      } else {
        return this.$message.error(res.msg)
      }
    },
    // 是否开发票
    async updateBill(userInfo) {
      const {data: res} = await this.$http.post('/bill/updateBill', userInfo)
      if (res.code !== 200) {
        userInfo.status = !userInfo.status
        return this.$message.error(res.msg)
      }
      this.dialogVisible = false
      await this.getBillListByPage()
      return this.$message.success('修改成功')
    }
  }
}
</script>

<style lang="less" scoped>

.item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>
