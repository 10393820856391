<template>
  <el-container class="home_container">
    <!-- 头部 -->
<!--    <el-header>-->
<!--      <img src="../assets/images/hit.png" alt="" class="img">-->
<!--      <span style="margin-right: 20px ; color: white; font-size: 20px;margin-left: 10px; align-items: center;">-->
<!--        发票查询系统-->
<!--      </span>-->
<!--    </el-header>-->
<!--    <el-container>-->
      <el-container>
        <!-- 主体 -->
        <el-main>
          <!-- 路由占位符 -->
          <router-view></router-view>
        </el-main>
      </el-container>
<!--    </el-container>-->
  </el-container>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.home_container {
  height: 100%;
}

.el-header {
  background-color: #ED7119;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.img {
  height: 29px;
  width: 240px;
}

.el-main {
  background-color: white;
  color: white;
  padding: 0;
}

</style>
