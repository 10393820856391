import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/global.css'
import axios from 'axios'
import urlPath from './url.js'

Vue.config.productionTip = false
Vue.use(ElementUI)
// 服务器IP地址
axios.defaults.baseURL = urlPath

// 配置请求挂载token
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.sessionStorage.getItem('tokenValue')
    return config
})
Vue.prototype.$http = axios

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
