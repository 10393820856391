<template>
  <!-- 主页 -->
  <div class="searchDiv" v-if="pageMark===1">
    <div class="searchContentDiv">
      <el-row>
        <el-col style="text-align: center">
          <img class="iconImg" src="../assets/images/hitgoo.png"/>
        </el-col>
      </el-row>
      <el-row style="margin-top: 40px">
        <el-col :span="24" style="text-align: center">
          <el-input v-model="searchValue"
                    size="large"
                    placeholder="请通过手机号查询发票信息"
                    @keyup.enter.native="onSearch"
                    style="width: 90%">
            <el-button slot="append" size="small" @click="toSearchPage">搜索</el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="24" style="text-align: center;">
          <el-button type="warning" size="medium" @click="toAddPage" style="width: 90%;height:40px;margin-bottom: 60px">
            新 增 发 票
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col style="text-align: center">
          <img src="../assets/images/ewm.jpg" style="width: 200px;height: 200px"/>
        </el-col>
      </el-row>
      <el-row>
        <el-col style="text-align: center">
          <span style="color: #ED7119">扫码关注公众号</span>
        </el-col>
      </el-row>
    </div>
  </div>

  <!-- 查询结果 -->
  <div class="resultDiv" v-else-if="pageMark===2">
    <el-row justify="space-between" style="margin-top: 10px">
      <el-col :span="6" style="text-align: left">
        <el-button icon="el-icon-house" type="warning" size="medium" @click="toMainPage">主页</el-button>
      </el-col>
      <el-col :span="18">
        <el-input v-model="searchValue" size="medium" placeholder="请通过手机号查询" clearable
                  @keyup.enter.native="onSearch">
          <el-button slot="append" size="mini" @click="getList()">搜索</el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row v-for="bill in billPageList" :key="bill.id" style="margin:5px 5px">
      <el-card style="border-radius: 8px">
        <el-descriptions :column="1">
          <el-descriptions-item label="开票状态">
            <el-tag type="danger" v-if="bill.status === '1'">未开票</el-tag>
            <el-tag type="success" v-else-if="bill.status === '2'">已开票</el-tag>
            <el-tag type="warning" v-else>待开票</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="公司名称(发票抬头)">{{ bill.companyName }}</el-descriptions-item>
          <el-descriptions-item label="统一社会信用代码(纳税人识别码)">{{ bill.billCode }}</el-descriptions-item>
          <el-descriptions-item label="购买门店名称">{{ bill.storeName }}</el-descriptions-item>
          <el-descriptions-item label="发票金额(元)">{{ bill.money }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{ bill.phone }}</el-descriptions-item>
          <el-descriptions-item label="邮箱地址">{{ bill.email }}</el-descriptions-item>
          <el-descriptions-item label="购物小票图片">
            <el-image
                style="width: 60px; height: 40px"
                :src="bill.imageNameList[0]"
                :preview-src-list="bill.imageNameList"
                fit="contain">
            </el-image>
          </el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ bill.createTime }}</el-descriptions-item>
          <el-descriptions-item label="原因">{{ bill.reason }}</el-descriptions-item>
          <el-descriptions-item label="备注">{{ bill.remark }}</el-descriptions-item>
        </el-descriptions>
      </el-card>
    </el-row>
  </div>

  <!-- 新增对话框 -->
  <div class="addDiv" v-else>
    <el-row justify="space-between" style="margin:5px 0">
      <el-col :span="8" style="text-align: left">
        <el-button icon="el-icon-house" type="warning" size="medium" @click="backMainPage">主页</el-button>
      </el-col>
    </el-row>
    <el-divider style="margin: 5px 0"></el-divider>
    <!-- 表单区域 -->
    <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="100px" label-position="left">
      <el-form-item label=" 公司名称（发票抬头）" prop="companyName" required>
        <el-input v-model.trim="addForm.companyName"></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码（纳税人识别码）" prop="billCode" required>
        <el-input v-model.trim="addForm.billCode"></el-input>
      </el-form-item>
      <el-form-item label="发票金额" prop="money" required>
        <el-input v-model.trim="addForm.money" placeholder="请输入购物小票上的真实金额"></el-input>
      </el-form-item>
      <el-form-item label="购买门店名称" prop="storeName">
        <el-input v-model.trim="addForm.storeName"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model.trim="addForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="addForm.email" placeholder="请输入真实邮箱"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="addForm.remark"></el-input>
      </el-form-item>
      <el-form-item label="上传小票" prop="filePath">
        <el-upload
            ref="upload"
            :action=uploadUrl()
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :on-preview="handlePreview"
            :before-upload="beforeUpload"
            :file-list="fileList"
            list-type="picture">
          <el-button size="small" type="warning">点击上传</el-button>
          <div slot="tip" class="el-upload__tip" style="color: red;line-height: 14px">请上传购物小票图片,否则无法开票<br/>
            等待图片右上角出现<span style="color: green">绿色√</span>,即上传成功</div>
        </el-upload>
      </el-form-item>
    </el-form>
    <!-- 对话框底部区域-->
    <el-row>
      <el-col>
        <el-button style="width: 100%;margin-bottom: 20px;height: 50px" type="primary" @click="addBill">
          <span style="font-size: 18px">提 交 开 票</span>
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import urlPath from '../url.js'
// 引入image-conversion进行图片压缩
import * as imageConversion from 'image-conversion'

export default {
  data() {
    return {
      pageMark: 1,
      fileList: [],
      headerCellStyleObj: {
        'background-color': '#409EFF',
        'color': '#fff',
        'font-weight': 400
      },
      searchValue: '', // 模糊查询参数
      select: '',
      page: {          // 分页参数
        pageNum: 1,
        pageSize: 10
      },
      total: 0,         // 总数量
      billPageList: [],  // 分页列表数据
      addDialogVisible: false,    // 控制新增对话框的显示和隐藏
      // 新增发票的表单数据
      addForm: {
        status: 0,
        companyName: '',
        billCode: '',
        money: '',
        phone: '',
        email: '',
        reason: '',
        createTime: '',
        remark: '',
        imageNameList: []
      },
      // 新增发票的验证规则
      addFormRules: {
        companyName: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
        billCode: [{required: true, message: '请输入统一社会信用代码', trigger: 'blur'}],
        money: [{required: true, message: '请输入开票金额', trigger: 'blur'}],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
        ],
        storeName: [
          {required: true, message: '请输入购买门店名称', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur'}
        ]
      },
    }
  },
  methods: {
    toAddPage() {
      this.pageMark = 3
    },
    toMainPage(){
      this.pageMark = 1
    },
    backMainPage() {
      this.$refs.upload.clearFiles()
      this.$refs.addFormRef.resetFields()
      this.pageMark = 1
    },
    toSearchPage() {
      this.pageMark = 2
      console.log(this.pageMark)
      this.getList()
    },
    onSearch() {
      this.getList()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log('file', file)
    },
    uploadUrl() {
      return `${urlPath}/bill/upload`
    },
    // 图片上传后返回路径给表单
    handleSuccess(res) {
      this.addForm.imageNameList = res.data
    },
    // 不考虑图片长宽,只考虑图片大小的情况,图片超过1M就压缩
    beforeUpload(file) {
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 / 1024 < 1 // 判定图片大小是否小于1MB
        if (isLt2M) {
          resolve(file)
        }
        // 压缩到400KB,这里的400就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 300).then(res => {
          resolve(res)
        })
      })
    },
    // 监听对话框状态,关闭对话框后清空数据
    addDialogClosed() {
      this.$refs.upload.clearFiles()
      this.$refs.addFormRef.resetFields()
    },
    addBill() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const {data: res} = await this.$http.post('/bill/insertBill', this.addForm)
        if (res.code === 200) {
          this.$refs.upload.clearFiles()
          this.$refs.addFormRef.resetFields()
          this.pageMark=1
          return this.$message.success('新增发票成功,搜索检查信息是否准确')
        } else {
          return this.$message.error(res.msg)
        }
      })
    },
    async getList() {
      const {data: res} = await this.$http.post('/bill/getList',
          {
            searchValue: this.searchValue
          })
      if (res.code === 200) {
        this.billPageList = res.data
        this.total = res.data.total
      } else {
        return this.$message.error(res.msg)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.searchDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
}

.searchContentDiv {
  width: 100%;
  height: 350px;
}

.resultDiv {
  overflow: hidden;
  background-color: white;
}

.addDiv {
  background-color: white;
  width: 98%;
  padding-left: 5px;
}

.el-row {
  background-color: white;
}

.iconImg {
  width: 60%;
}

.el-card {
  height: 99.5%;
  width: 100%;
}

.el-descriptions {
  margin-top: 15px;
}

.el-divider--horizontal{
  margin: 10px 0;
}

/deep/.el-form-item__label {
  margin-top: 10px;
  line-height: 20px;
}
</style>
