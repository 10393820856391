import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/home'
import WelCome from './components/welcome'
import Bill from './components/bill'
import Login from './components/login'

Vue.use(Router)

const router = new Router({
    routes: [
        {path: '/', redirect: '/welcome'},
        {
            path: '/home', component: Home, redirect: '/welcome',
            children: [
                {path: '/login', component: Login},
                {path: '/welcome', component: WelCome},
                {path: '/bill', component: Bill}
            ]
        }
    ]
})

export default router

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to 代表将要访问的路径
    // from 代表从哪个路径而来
    // next 代表去哪个路径
    if (to.path === '/login') {
        return next()
    } else if (to.path === '/welcome' || to.path === '/rand') {
        return next()
    } else {
        // 获取token
        const token = window.sessionStorage.getItem('Authorization')
        if (!token) {
            return next('/login')
        } else {
            return next()
        }
    }
})



