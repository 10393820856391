<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../assets/images/3.png" alt="">
      </div>
      <!-- 登录表单区域 -->
      <el-form :model="loginForm" :rules="rules" ref="loginFormRef" class="login_form" @submit.native.p.prevent>
        <el-form-item prop="no">
          <el-input v-model="loginForm.no" prefix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password"
                    prefix-icon="el-icon-unlock"
                    :type="psw">
            <i slot="suffix" :class="icon" @click="showPsw"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-row style="margin-bottom: 10px">
            <el-col :span="12" style="text-align: left">
              <el-checkbox v-model="checked" class="autoCheck">记住我</el-checkbox>
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-link type="danger" @click="openPwd">忘记密码</el-link>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="text-align: right">
              <el-button @click="resetForm">重置</el-button>
              <el-button type="primary" @click="submitForm" native-type="submit">登录</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: true,
      psw: 'password',
      icon: 'el-input__icon el-icon-view',
      tokenValue: '',
      loginForm: {
        no: '',
        password: '',
      },
      rules: {
        no: [
          {required: true, message: '请输入用户账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'change'}
        ]
      }
    }
  },
  created() {
    this.getCookie()
  },
  methods: {
    openPwd() {
      this.$message.warning('请联系负责人修改密码')
    },
    //密码的隐藏和显示
    showPsw() {
      //点击图标是密码隐藏或显示
      if (this.psw === 'text') {
        this.psw = 'password'
        this.icon = 'el-input__icon el-icon-view'
      } else {
        this.psw = 'text'
        this.icon = 'el-input__icon el-icon-loading'
      }
    },
    submitForm() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (valid) {
          // 点击登录按钮的时候，判断是否勾选了自动登录（记住密码），对cookie做相应操作
          if (this.checked) {
            // 传入账号名，密码，和保存天数3个参数
            this.setCookie(this.loginForm.no, this.loginForm.password, 5)
          } else {
            // 如果没有选中自动登录，那就清除cookie
            this.setCookie('', '', -1) // 修改2值都为空，天数为负1天就好了
          }
          const {data: res} = await this.$http.post('/login/login', this.loginForm)
          if (res.code === 200) {
            this.$message.success('登录成功')
            // 设置token到sessionStorage中
            window.sessionStorage.setItem('Authorization', res.data.tokenValue)
            // 登录成功跳转主页
            await this.$router.push('/bill')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    resetForm() {
      this.$refs.loginFormRef.resetFields()
    },
    // 设置cookie
    setCookie(name, pwd, exdays) {
      var exdate = new Date()// 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
      // 字符串拼接cookie
      window.document.cookie = 'billno' + '=' + name + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'billpassword' + '=' + pwd + ';path=/;expires=' + exdate.toGMTString()
    },
    // 读取cookie 将用户名和密码回显到input框中
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ')// 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=')// 再次切割
          // 判断查找相对应的值
          if (arr2[0] === 'billno') {
            this.loginForm.no = arr2[1]// 保存到保存数据的地方
          } else if (arr2[0] === 'billpassword') {
            this.loginForm.password = arr2[1]
          }
        }
      }
    },
  }
}
</script>
<!--#090723-->
<style lang="less" scoped>
.login_container {
  background-color: #333333;
  height: 100%;
}

.child_box {
  height: 300px;
  width: 450px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(50%, -50%);

  img {
    height: 100%;
    width: 100%;
  }
}

.login_box {
  background-color: sandybrown;
  height: 330px;
  width: 450px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.avatar_box {
  height: 100px;
  width: 100px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: darkorange;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: darkorange;
  }
}

.button {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
}
</style>
